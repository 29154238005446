import * as THREE from "three";
import vertexshader from "./vertexshader.glsl";
import fragmentshader from "./fragmentshader.glsl";
import { Petal } from "@/three/petal";
import seedrandom from "seedrandom";

const NUM_PARTICLES = 80;
const SPEED_MODIFIER = 16;

export const LOOP = false;
const TIME_BETWEEN_LOOP_STARTS_MS = 23000;
const NUMBER_PARTICLE_SYSTEMS = 4;

export class Renderer {
  private readonly scene: THREE.Scene;
  private readonly camera: THREE.OrthographicCamera;
  private renderer: THREE.WebGLRenderer;
  private readonly particleGeometry: THREE.BufferGeometry =
    new THREE.BufferGeometry();
  private particleArrays: Petal[][] = [];
  private lastFrame: DOMHighResTimeStamp;

  private timeSinceLastLoopStart = 0;
  private loopIndex = 0;

  constructor(domElement: Element) {
    this.scene = new THREE.Scene();
    this.camera = new THREE.OrthographicCamera(-160, 160, -90, 90, 1, 10000);

    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.renderer.setSize(1920, 1080);
    domElement.appendChild(this.renderer.domElement);

    const pMaterial = new THREE.ShaderMaterial({
      uniforms: {
        pointTexture: { value: new THREE.TextureLoader().load("petal4.png") },
      },
      vertexShader: vertexshader,
      fragmentShader: fragmentshader,
      depthTest: true,
    });

    for (let i = 0; i < NUMBER_PARTICLE_SYSTEMS; i++) {
      this.particleArrays.push([]);
    }

    this.createParticles();

    const particleSystem = new THREE.Points(this.particleGeometry, pMaterial);

    if (!LOOP) {
      for (const particles of this.particleArrays) {
        for (const particle of particles) {
          particle.update(1000);
        }
      }
    }

    // add it to the scene
    this.scene.add(particleSystem);

    this.camera.position.z = 50;
    this.lastFrame = performance.now();
    requestAnimationFrame(this.animate.bind(this));
  }

  private createParticles(): void {
    if (LOOP) {
      seedrandom("SEEED", { global: true });
      this.particleArrays[this.loopIndex] = [];
    }

    for (let p = 0; p < NUM_PARTICLES; p++) {
      this.particleArrays[this.loopIndex].push(new Petal());
    }
    this.loopIndex = (this.loopIndex + 1) % NUMBER_PARTICLE_SYSTEMS;

    this.particleGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(
        new Float32Array(
          this.particleArrays.flatMap((particles) =>
            particles.flatMap((value) => [value.x, value.y, value.z])
          )
        ),
        3
      )
    );
    this.particleGeometry.setAttribute(
      "aRotation",
      new THREE.BufferAttribute(
        new Float32Array(
          this.particleArrays.flatMap((particles) =>
            particles.map((value) => value.rot)
          )
        ),
        1
      )
    );
  }

  private animate(timestamp: number) {
    const elapsedTime = timestamp - this.lastFrame;
    this.lastFrame = timestamp;

    if (LOOP) {
      this.timeSinceLastLoopStart += elapsedTime;
      if (this.timeSinceLastLoopStart > TIME_BETWEEN_LOOP_STARTS_MS) {
        this.timeSinceLastLoopStart = 0;
        this.createParticles();
      }
    }

    this.updateParticles(elapsedTime);

    this.renderer.setClearAlpha(0);
    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);

    requestAnimationFrame(this.animate.bind(this));
  }

  private updateParticles(elapsed_time: number): void {
    const positionAttribute = this.particleGeometry.getAttribute("position");
    const rotationAttribute = this.particleGeometry.getAttribute("aRotation");

    for (let i = 0; i < NUM_PARTICLES; i++) {
      for (let j = 0; j < NUMBER_PARTICLE_SYSTEMS; j++) {
        const particle = this.particleArrays[j][i];
        if (particle) {
          particle.update(elapsed_time / SPEED_MODIFIER);
          positionAttribute.setXYZ(
            j * NUM_PARTICLES + i,
            particle.x,
            particle.y,
            particle.z
          );
          rotationAttribute.setX(j * NUM_PARTICLES + i, particle.rot);
        }
      }
    }

    positionAttribute.needsUpdate = true;
    rotationAttribute.needsUpdate = true;
    this.particleGeometry.setAttribute("position", positionAttribute);
    this.particleGeometry.setAttribute("aRotation", rotationAttribute);
  }
}
