




import Countdown from "../components/Countdown.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: { Countdown },
})
export default class PlainCountdown extends Vue {
  private dateNow = new Date();

  @Prop()
  private time!: number;

  private get targetDate(): Date {
    return new Date(this.dateNow.getTime() + this.time * 60000 + 1000);
  }
}
