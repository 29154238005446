






























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Countdown extends Vue {
  private now: number = Date.now();

  @Prop()
  private targetDate!: Date;

  @Prop({ default: false })
  private shorten!: boolean;

  private static format(n: number, ignore?: boolean) {
    if (ignore) {
      return "" + n;
    }
    return (n < 10 ? "0" : "") + n;
  }

  private static extractTime(remainingDiff: number) {
    const days = Math.floor(remainingDiff / 1000 / 60 / 60 / 24);
    remainingDiff -= days * 24 * 60 * 60 * 1000;

    const hours = Math.floor(remainingDiff / 1000 / 60 / 60);
    remainingDiff -= hours * 60 * 60 * 1000;

    const minutes = Math.floor(remainingDiff / 1000 / 60);
    remainingDiff -= minutes * 60 * 1000;

    const seconds = Math.floor(remainingDiff / 1000);

    return { days, hours, minutes, seconds };
  }

  private static shortenCountdown(
    days: number,
    hours: number,
    minutes: number,
    seconds: number
  ) {
    let result = "";
    if (days > 0) {
      result += `${days}:`;
    }
    if (hours > 0 || days > 0) {
      result += `${Countdown.format(hours, days === 0)}:`;
    }
    result += `${Countdown.format(
      minutes,
      days === 0 && hours === 0
    )}:${Countdown.format(seconds)}`;
    return result;
  }

  private get countdownTime(): string {
    let remainingDiff: number = this.targetDate.getTime() - this.now;

    if (remainingDiff < 0) {
      return (remainingDiff / 1000) % 2 < -1
        ? this.shorten
          ? "0:00"
          : "0:00:00:00"
        : "";
    }
    const { days, hours, minutes, seconds } =
      Countdown.extractTime(remainingDiff);

    if (this.shorten) {
      return Countdown.shortenCountdown(days, hours, minutes, seconds);
    }

    return `${days}:${Countdown.format(hours)}:${Countdown.format(
      minutes
    )}:${Countdown.format(seconds)}`;
  }

  private updateNow() {
    this.now = Date.now();
  }

  // noinspection JSUnusedLocalSymbols
  private mounted(): void {
    this.updateNow();
    setInterval(this.updateNow.bind(this), 500);
  }
}
