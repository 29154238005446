import { Vector2 } from "three";
import { LOOP } from "@/three/renderer";

const ROTATION_VELOCITY_FACTOR = 0.03;
const ROTATION_VELOCITY_SUMMAND = 0;
const VELOCITY_FACTOR = 0.25;
const VELOCITY_SUMMAND = 0.1;
const DIRECTION_ANGLE_FACTOR = 0.15;
const DIRECTION_ANGLE_SUMMAND = 0.05;

export class Petal {
  public x = 0;
  public y = 0;
  public z = 0;
  public rot = 0;

  private vx = 0;
  private vy = 0;
  private vr = 0;

  constructor() {
    this.reset();
  }

  public update(steps = 1): void {
    if (this.x > 180 || this.y > 100) {
      if (LOOP) {
        return;
      }
      this.reset();
    }
    this.x += this.vx * steps;
    this.y += this.vy * steps;
    this.rot += this.vr * steps;
  }

  private reset(): void {
    this.x = Math.random() * 150 - 250;
    this.y = Math.random() * 150 - 250;
    this.z = Math.random() * 45 - 35;
    this.rot = Math.random() * 2 * Math.PI;

    const velocity = new Vector2(
      Math.random() * VELOCITY_FACTOR + VELOCITY_SUMMAND,
      0
    ).rotateAround(
      new Vector2(0, 0),
      (Math.random() * DIRECTION_ANGLE_FACTOR + DIRECTION_ANGLE_SUMMAND) *
        2 *
        Math.PI
    );

    this.vx = velocity.x;
    this.vy = velocity.y;
    this.vr =
      Math.random() * ROTATION_VELOCITY_FACTOR + ROTATION_VELOCITY_SUMMAND;
  }
}
