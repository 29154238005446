import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Petals from "../views/Petals.vue";
import PlainCountdown from "../views/PlainCountdown.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/petals",
    name: "Petals",
    component: Petals,
  },
  {
    path: "/countdown",
    name: "Countdown",
    component: PlainCountdown,
    props: (route) => ({
      time: Array.isArray(route.query.time) ? 0 : parseInt(route.query.time),
    }),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
