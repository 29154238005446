








import Renderer from "../components/Renderer.vue";
import Background from "../components/Background.vue";
import Countdown from "../components/Countdown.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: { Renderer, Background, Countdown },
})
export default class Home extends Vue {
  private targetDate = new Date(process.env.VUE_APP_COUNTDOWN_DATE);
}
