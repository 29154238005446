




import Renderer from "../components/Renderer.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: { Renderer },
})
export default class Petals extends Vue {}
