















import Vue from "vue";
import Component from "vue-class-component";
import { Renderer as PetalRenderer } from "../three/renderer";

@Component
export default class Renderer extends Vue {
  // noinspection JSUnusedLocalSymbols
  private mounted(): void {
    new PetalRenderer(this.$refs.threeContainer as Element);
  }
}
